<template>
  <v-footer
    padless
    class="contact-bg"
    :style="{ 'background-image': 'url(' + backgroundImage + ')' }"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="5"
          offset-xl="1"
          lg="4"
          xl="3"
          align-self="center"
          class="px-10"
        >
          <h6 class="text-uppercase text--heading">
            Call us for Questions
          </h6>
          <div class="contact-details">
            <v-icon>mdi-phone</v-icon>
            <div class="primary--text text">
              +61 408 896 644
            </div>
          </div>
          <h6 class="text-uppercase text--heading">
            Email us for Enquiries
          </h6>
          <div class="contact-details">
            <v-icon>mdi-email</v-icon>
            <div class="primary--text text">
              mick@pinkzebra.com.au
            </div>
          </div>
          <br>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="5"
          xl="4"
        >
          <div
            v-if="contactFooter == true"
            id="contact"
            class="black white--text text-center contact-form-container mt-md-7 mb-md-n16"
            name="contact"
          >
            <h2>Send Us a Message!</h2>
            <contact-form />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div
      class="white"
      style="width: 100%;"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="10"
            offset-xl="1"
            xl="9"
          >
            <v-btn
              v-for="(item, id) in socialLinks"
              :key="id"
              class="me-2 my-3 "
              icon
              outlined
            >
              <v-icon size="20px">
                {{ item.icon }}
              </v-icon>
            </v-btn>
            <br>
            <p class="text-caption">
              &copy; {{ new Date().getFullYear() }} Pink Zebra. All rights reserved.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-footer>
</template>

<script>
import contactForm from '@/components/form-contact.vue'

export default ({
  name: 'Footer',
  components: {
    contactForm
  },
  props: {
    contactFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      socialLinks: [
        {
          name: 'Pink Zebra Facebook',
          icon: 'mdi-facebook',
          url: '#'
        },
        {
          name: 'Pink Zebra Twitter',
          icon: 'mdi-twitter',
          url: '#'
        },
        {
          name: 'Pink Zebra Instagram',
          icon: 'mdi-instagram',
          url: '#'
        }
      ],
      backgroundImage: require('@/assets/images/contact-bg-01.jpg')
    }
  }
})
</script>
