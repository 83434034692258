import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#EC088C', // PZ Pink
        secondary: '#535353' // PZ Dark Grey
      }
    }
  }
})
