<template>
  <v-app>
    <v-main>
      <div
        class="fs-hero overlay"
        :style="{ 'background-image': 'url(' + backgroundImage + ')' }"
        align="center"
        elevate="0"
      >
        <v-container>
          <v-row>
            <v-col class="d-flex justify-space-between align-center">
              <img
                :src="require('@/assets/images/logo-pz.png')"
                style="max-width: 100px;"
              >
              <v-spacer />
              <v-btn
                v-scroll-to="{ element: '#contact', duration: 1500 }"
                color="primary"
                large
              >
                Contact Us
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="fs-hero-offset">
        <router-view />
      </div>
    </v-main>
    <Footer :contact-footer="true" />
  </v-app>
</template>

<script>
import { setupGTag, setupRecaptcha } from '@/utils/tracking.js'
import Footer from '@/layout/includes/footer.vue'

export default ({
  components: {
    Footer
  },
  data() {
    return {
      backgroundImage: require('@/assets/images/hero-banner-01.jpg'),
      search: ''
    }
  },
  created() {
    console.log('created')
    setupGTag()
    setupRecaptcha()
  }
})
</script>
