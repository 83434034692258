<template>
  <v-form
    ref="contactForm"
  >
    <v-text-field
      v-model="contact.name"
      label="Name"
      placeholder="Name"
      solo
      dense
      required
      :error-messages="nameErrors"
    />
    <v-text-field
      v-model="contact.phone"
      label="Phone"
      placeholder="Phone"
      solo
      dense
      required
      :error-messages="phoneErrors"
    />
    <v-text-field
      v-model="contact.email"
      label="Email"
      placeholder="email"
      solo
      dense
      required
      :error-messages="emailErrors"
    />
    <v-textarea
      v-model="contact.message"
      placeholder="Your Message"
      solo
      dense
      required
      :error-messages="messageErrors"
    />
    <v-alert
      v-if="response.show"
      :type="response.type"
    >{{ response.message }}</v-alert>
    <v-btn
      color="primary"
      large
      :loading="loading"
      @click="submit"
    >
      Submit
    </v-btn>
  </v-form>
</template>

<script>
import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'

export default ({
  data() {
    return {
      loading: false,
      response: {
        show: false,
        message: '',
        type: 'success'
      },
      contact: {
        name: '',
        phone: '',
        email: '',
        message: ''
      }

    }
  },
  validations: {
    contact: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required
      },
      message: {
        required
      }
    }
  },
  computed: {
    message() {
      let msg = `Name: ${this.contact.name}` + '\n'
      msg += `Phone: ${this.contact.phone}` + '\n'
      msg += `Email: ${this.contact.email}` + '\n'
      msg += `comment: ${this.contact.message}` + '\n'
      return msg
    },
    nameErrors() {
      const errors = []
      if (!this.$v.contact.name.$dirty) return errors
      !this.$v.contact.name.required && errors.push('Name is required.')
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.contact.phone.$dirty) return errors
      !this.$v.contact.phone.required && errors.push('Phone is required.')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.contact.email.$dirty) return errors
      !this.$v.contact.email.required && errors.push('Email is required.')
      !this.$v.contact.email.email && errors.push('Please enter a valid email address.')
      return errors
    },
    messageErrors() {
      const errors = []
      if (!this.$v.contact.message.$dirty) return errors
      !this.$v.contact.message.required && errors.push('Message is required.')
      return errors
    }
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.setError('Please enter all the required fields.')
        return
      }
      this.loading = true
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
            action: 'submit'
          })
          .then(token => {
            axios.post(process.env.VUE_APP_MAIL_ENDPOINT, {
              toEmails: [process.env.VUE_APP_ADMIN_EMAIL],
              subject:
                    'Pink Zebra Website Enquiry',
              message: this.message
            }).then(() => {
              this.setSuccess()
            })
              .catch(() => this.setError('An error occur. Please try again later.'))
          }).catch(() => {
            this.setError('Invalid request. Please refresh the page and try again.')
          })
      })
    },
    setSuccess() {
      this.response.message = 'Thank you! We will get back to you shortly.'
      this.response.type = 'success'
      this.response.show = true
      this.loading = false
    },
    setError(err) {
      this.response.message = err
      this.response.type = 'error'
      this.response.show = true
      this.loading = false
    }
  }
})
</script>
<style scoped>

</style>
