import Vue from 'vue'
import VueRouter from 'vue-router'

// Layouts
import HomepageLayout from '../layout/HomepageLayout.vue'
import NoLayout from '../layout/NoLayout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homepage',
    redirect: '/home',
    component: HomepageLayout,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue')
      }
    ]
  },
  {
    path: '/briefing',
    redirect: 'briefing/company',
    component: NoLayout,
    children: [
      {
        path: '/briefing/company',
        name: 'Microsite Briefing - Company',
        component: () => import('@/views/briefings/BriefingCompany.vue')
      },
      {
        path: '/briefing/options',
        name: 'Microsite Briefing - Options',
        component: () => import('@/views/briefings/BriefingOptions.vue')
      },
      {
        path: '/briefing/promotional',
        name: 'Microsite Briefing - Promotional Microsite',
        component: () => import('@/views/briefings/BriefingPromotional.vue')
      },
      {
        path: '/briefing/content',
        name: 'Microsite Briefing - Content Based Websites',
        component: () => import('@/views/briefings/BriefingContent.vue')
      },
      {
        path: '/briefing/ecommerce',
        name: 'Microsite Briefing - Ecommerce',
        component: () => import('@/views/briefings/BriefingEcommerce.vue')
      },
      {
        path: '/briefing/confirmation',
        name: 'Microsite Briefing - Confirmation',
        component: () => import('@/views/briefings/BriefingConfirmation.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
