
const setupGTag = () => {
  const gtagScript = document.createElement('script')
  gtagScript.setAttribute(
    'src',
    'https://www.googletagmanager.com/gtag/js?id=' +
          process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID
  )
  document.head.appendChild(gtagScript)

  window.dataLayer = window.dataLayer || []
  // eslint-disable-next-line no-undef
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())

  gtag('config', process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID)
}

const setupRecaptcha = () => {
  const recaptcha = document.createElement('script')
  recaptcha.setAttribute(
    'src',
    'https://www.google.com/recaptcha/api.js?render=' +
        process.env.VUE_APP_RECAPTCHA_SITE_KEY
  )
  document.head.appendChild(recaptcha)
}

export { setupGTag, setupRecaptcha }

