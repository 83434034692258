<template>
  <v-app>
    <v-main>
      <div class="my-10">
        <v-container>
          <v-row>
            <v-col class="text-center">
              <img
                :src="require('@/assets/images/logo-pz.png')"
                style="max-width: 100px;"
              >
            </v-col>
          </v-row>
        </v-container>
        <router-view />
      </div>
    </v-main>
    <Footer :contact-footer="false" />
  </v-app>
</template>

<script>
import { setupGTag, setupRecaptcha } from '@/utils/tracking.js'
import Footer from '@/layout/includes/footer.vue'

export default ({
  components: {
    Footer
  },
  created() {
    console.log('created')
    setupGTag()
    setupRecaptcha()
  }
})
</script>
