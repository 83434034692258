import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'

// Plugins
import vuetify from './plugins/vuetify'

// Custome SCSS
import './scss/custom.scss'
Vue.config.productionTip = false

var VueScrollTo = require('vue-scrollto')

Vue.use(VueScrollTo)

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.use(Vuelidate)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.mixin({
  methods: {
    scrollToTop: function() {
      window.scrollTo(0, 0)
    }
  }
})
